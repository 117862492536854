import React, { useState } from "react";
import Spinner from "./Spinner";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import DeactivatePlan from "./Modals/PlanList/DeactivatePlan";
import ActivatePlan from "./Modals/PlanList/ActivatePlan";
import CreatePlan from "./Modals/PlanList/CreatePlan";
import { usePlanStore } from "../stores/plans-store";
import { useEffect } from "react";
import { useCommonStore } from "../stores/common-store";
import { getContent } from "../helpers/FetchContent";

export default function PlansListDetails({ data }) {
	const isLoading = useCommonStore(state => state.isLoading);
	const plans = usePlanStore(state => state.plans);
	const setPlans = usePlanStore(state => state.setPlans);

	const [selectedPlanId, setSelectedPlanId] = useState(0);
	const [selectedPlanName, setSelectedPlanName] = useState(0);
	const [showDeactivate, setShowDeactivate] = useState(false);

	const handleShowDeactivate = (planId, planName) => {
		setSelectedPlanId(planId);
		setShowDeactivate(true);
		setSelectedPlanName(planName);
	};

	const closeDeactivateDialog = () => setShowDeactivate(false);

	useEffect(() => {
		setPlans(data);
	}, [data]);

	const reload = () => window.location.reload();
	if (!plans || isLoading) {
		return <Spinner />;
	}
	return (
		<div className="px-4 sm:px-6 lg:px-8">
			{/* Modals [start] */}
			<DeactivatePlan
				btnText={"Deactivate"}
				modalTitle={"Deactivate Plan " + selectedPlanName + " "}
				modalBody={"This will deactivate the following Plan:"}
				plan_name={selectedPlanName}
				plan_id={selectedPlanId}
				open={showDeactivate}
				onClose={closeDeactivateDialog}
			/>
			{/* Modals [end] */}
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Plans</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all our Plans.</p>
				</div>
				<div className="sm:flex-auto text-right">
					<CreatePlan onClose={reload} />
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
										>
											Plan Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Amount
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Terahashes
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Duration
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Active
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Details</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y-2">
									{plans.map(plan => (
										<tr key={plan.id}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
												<div className="flex items-center">
													<div className="">
														<div className="font-medium">{plan.plan_name}</div>
													</div>
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{plan.amount}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{plan.tera_hashes}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{plan.duration_days}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">
													{plan.active === true && <CheckIcon style={{ height: 25 }} />}
													{plan.active === false && <XIcon style={{ height: 25 }} />}
												</div>
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
												{plan.active === true && (
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowDeactivate(plan.id, plan.plan_name);
														}}
													>
														Deactivate
													</button>
												)}
												{plan.active === false && (
													<span className="ml-4 space-x-4">
														<ActivatePlan
															btnText={"Activate"}
															modalTitle={"Activate Plan " + plan.plan_name + " "}
															modalBody={"This will activate the following Plan:"}
															plan_name={plan.plan_name}
															plan_id={plan.id}
															onClose={reload}
														/>
													</span>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
