import React from "react";
import Base from "../components/Base";
import setNavigation from "../helpers/SetNavigation";

export default function AffiliatePerformance() {

	const navigation = setNavigation("Affiliate Performance");

	return <Base navigation={navigation} crumb={"Affiliate Performance"} />;
}
