import React from "react";
import Base from "../components/Base";
import setNavigation from "../helpers/SetNavigation";

export default function SalesPerformance() {

	const navigation = setNavigation("Sales Performance");

	return <Base navigation={navigation} crumb={"Sales Performance"} />;
}
