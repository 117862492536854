import React from "react";
import Base from "../components/Base";
import setNavigation from "../helpers/SetNavigation";

export default function Users() {

	const navigation = setNavigation("Users");

	return <Base navigation={navigation} crumb={"Users"} />;
}
