import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useCommonStore } from "../stores/common-store";
import Spinner from "./Spinner";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function DashboardList(props) {
	const isLoading = useCommonStore(state => state.isLoading);
	const chart_data_set = {};
	const labels = [];
	const datasets = [];

	const options = {
		responsive: true,
		plugins: {
			title: {
				display: true,
				text: "Contracts"
			}
		}
	};

	const chart_data = {
		labels,
		datasets
	};

	function find_year(year) {
		let year_found = false;
		let index_found = datasets.length;
		if (datasets.length > 0) {
			datasets.map((label, index) => {
				if (label.label == year) {
					index_found = index;
					year_found = true;
				}
			});
		}
		return { year_found: year_found, index: index_found };
	}

	function random_color() {
		return (
			Math.floor(Math.random() * 256) +
			", " +
			Math.floor(Math.random() * 256) +
			", " +
			Math.floor(Math.random() * 256)
		);
	}

	function format_date(dateStr) {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const date = new Date(dateStr.created_at);
		const month = months[date.getMonth()];
		const year = date.getFullYear();
		const result = find_year(year);

		if (!result.year_found) {
			chart_data_set[year] = {};
			datasets[result.index] = {
				label: year,
				data: chart_data_set[year],
				borderColor: `rgb(${random_color()})`,
				backgroundColor: `rgba(${random_color()})`
			};
		}
		if (!chart_data_set[year][month]) {
			chart_data_set[year][month] = 0;
		}
		chart_data_set[year][month] = chart_data_set[year][month] + 1;
	}

	props.data.contract_data.map(apiDate => {
		format_date(apiDate);
	});

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className="px-6 pt-6 2xl:container">
			<div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
				<Bar options={options} data={chart_data} />
			</div>
		</div>
	);
}
