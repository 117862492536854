import { CubeIcon, UserIcon, HomeIcon, InboxIcon, ArchiveIcon, VideoCameraIcon } from "@heroicons/react/outline";

function setNavigation(crumb) {
	const navigation = [
		{ name: "Dashboard", href: "/", icon: HomeIcon, isSvg: false, current: false },
		{ name: "Users", href: "/users/", icon: UserIcon, isSvg: false, current: false },
		{ name: "Customers", href: "/customers/", icon: CubeIcon, isSvg: false, current: false },
		{ name: "Affiliate Performance", href: "/affiliate-performance/", icon: 'affiliate', isSvg: true, current: false },
		{ name: "Sales Performance", href: "/sales-performance/", icon: 'sales', isSvg: true, current: false },
		{ name: "Contracts", href: "/contracts/", icon: InboxIcon, isSvg: false, current: false },
		{ name: "Plans", href: "/plans/", icon: ArchiveIcon, isSvg: false, current: false },
		{ name: "Content Videos", href: "/content_videos/", icon: VideoCameraIcon, isSvg: false, current: false }
	];

	const index = navigation.map(element => element.name).indexOf(crumb);
	navigation[index].current = true;

	return navigation;
}

export default setNavigation;
