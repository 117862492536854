import React, { useState, useEffect } from "react";
import Base from "../components/Base";
import setNavigation from "../helpers/SetNavigation";
import { getContentWithoutState } from "../helpers/FetchContent";
import { useCommonStore } from "../stores/common-store";

export default function Dashboard() {
	const setIsLoading = useCommonStore(state => state.setIsLoading);
	const [contractInfo, setContractInfo] = useState(null);
	const [contractData, setContractData] = useState([]);

	const navigation = setNavigation("Dashboard");

	const getContracts = async () => {
		await setIsLoading(true);
		const contracts = await getContentWithoutState(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/contracts/`,
		);
		await setContractInfo(contracts);
		await setIsLoading(false);
	};

	useEffect(() => {
		if (localStorage.getItem("token") && contractInfo === null) {
			getContracts();
		}
		if (contractInfo && contractData.length < 1) {
			setContractData(contractInfo);
		}
	}, [contractInfo, contractData]);

	return <Base navigation={navigation} crumb={"Dashboard"} contract_data={contractData} />;
}
