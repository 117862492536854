import React, { useEffect, useState } from "react";
import { getLoginToken, checkLoginToken } from "../../helpers/FetchContent";
import { useHistory } from "react-router-dom";
import ErrorMsgComponent from "../../helpers/Errors";
import { ReactComponent as Logo } from "../../logo.svg";

export default function CustomLogin() {
	const [errMsg, setErrMsg] = useState();
	const history = useHistory();
	const [state, setState] = useState({
		email: "",
		password: ""
	});
	const [previousPath, setPreviousPath] = useState("");

	function checkPath() {
		const queryParams = new URLSearchParams(window.location.search);

		// Check if the "path" parameter exists
		if (queryParams.has("path")) {
			setPreviousPath(queryParams.get("path"));
			setErrMsg("Your session has expired. Please log in again.");
		}
	}

	function handleChange(e) {
		const copy = { ...state };
		// @ts-ignore
		copy[e.target.name] = e.target.value;
		setState(copy);
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setErrMsg("");

		await getLoginToken(state);

		if (localStorage.getItem("token")) {
			history.push("/");
			if (await checkLoginToken()) {
				if (previousPath !== "") {
					history.push(previousPath);
				} else {
					history.push("/");
				}
			} else {
				history.push("/login");
			}
		} else {
			setErrMsg("Invalid Email or Password");
		}
	}

	useEffect(() => {
		checkPath();
	}, []);

	return (
		<>
			<div className="h-screen flex bg-neutral-800">
				<div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96">
						<div className="flex text-4xl p-3 fill-primary">
							<Logo />
						</div>
						<div className="mt-8">
							<div className="mt-6">
								<form onSubmit={handleSubmit} className="space-y-6">
									<div>
										<label htmlFor="username" className="block text-sm font-medium text-white">
											Email address
										</label>
										<div className="mt-1">
											<input
												id="email"
												name="email"
												type="email"
												autoComplete="email"
												value={state.email}
												onChange={handleChange}
												required
												className="appearance-none block w-full px-3 py-2 border border-yellow-200 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
											/>
										</div>
									</div>

									<div className="space-y-1">
										<label htmlFor="password" className="block text-sm font-medium text-white">
											Password
										</label>
										<div className="mt-1">
											<input
												id="password"
												name="password"
												type="password"
												autoComplete="current-password"
												value={state.password}
												onChange={handleChange}
												required
												className="appearance-none block w-full px-3 py-2 border border-yellow-200 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
											/>
										</div>
									</div>

									{errMsg && <ErrorMsgComponent errMsg={errMsg} />}

									<div>
										<button
											type="submit"
											className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-primary"
										>
											Sign in
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className="hidden lg:block relative w-0 flex-1">
					<img
						className="absolute inset-0 h-full w-full object-cover"
						src="/hero-background-image.jpg"
						alt=""
					/>
				</div>
			</div>
		</>
	);
}
