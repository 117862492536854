import React, { useEffect, useState } from "react";
import DeleteUser from "./Modals/UserList/DeleteUser";
import Spinner from "./Spinner";
import { CheckIcon, XIcon, PlusIcon } from "@heroicons/react/solid";
import UserForm from "./Modals/UserList/UserForm";
import AssignUser from "./Modals/UserList/AssignUser";
import { useCommonStore } from "../stores/common-store";
import { getContent } from "../helpers/FetchContent";
import { useUserStore } from "../stores/users-store";

export default function UsersListDetails() {
	const setUsers = useUserStore(state => state.setUsers);
	const filters = useUserStore(state => state.filters);
	const setFilters = useUserStore(state => state.setFilters);
	const users = useUserStore(state => state.users);
	const isLoading = useCommonStore(state => state.isLoading);
	const setIsLoading = useCommonStore(state => state.setIsLoading);
	const [isEdit, setIsEdit] = useState(false);

	function check_user(user) {
		if (user.level === 99) {
			return true;
		}
		if (!user.customer_id) {
			return false;
		}

		return true;
	}

	useEffect(() => {
		getUsers();
	}, []);

	const handleFilter = async e => {
		e.preventDefault();
		getUsers();
	};

	const getUsers = async () => {
		await getContent(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/users/?name=${filters.name}&email=${filters.email}`,
			setUsers
		);
		setIsLoading(false);
	};

	const handleChange = e => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value
		});
	};

	const [selectedUserId, setSelectedUserId] = useState(0);
	const [selectedUserEmail, setSelectedUserEmail] = useState(0);
	const [showAssign, setShowAssign] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const handleShowAssign = userId => {
		setSelectedUserId(userId);
		setShowAssign(true);
	};

	const handleShowDetails = userId => {
		setSelectedUserId(userId);
		setIsEdit(true);
		setShowDetails(true);
	};

	const handleShowCreate = () => {
		setIsEdit(false);
		setShowDetails(true);
	};

	const handleShowDelete = (userId, userEmail) => {
		setSelectedUserId(userId);
		setSelectedUserEmail(userEmail);
		setShowConfirmDelete(true);
	};

	const closeAssignDialog = () => setShowAssign(false);
	const closeDetailsDialog = () => setShowDetails(false);
	const closeDeleteDialog = () => setShowConfirmDelete(false);

	if (!users || isLoading) {
		return <Spinner />;
	}

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			{/* Modals [start] */}
			<AssignUser open={showAssign} user_id={selectedUserId} onClose={closeAssignDialog} />
			<UserForm isEdit={isEdit} open={showDetails} user_id={selectedUserId} onClose={closeDetailsDialog} />
			<DeleteUser
				btnText={"Delete"}
				modalTitle={"Delete User " + selectedUserEmail + " "}
				modalBody={"This will delete the following User with Email:"}
				email={selectedUserEmail}
				user_id={selectedUserId}
				open={showConfirmDelete}
				onClose={closeDeleteDialog}
			/>
			{/* Modals [end] */}
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Users</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all our Users.</p>
				</div>
				<div className="sm:flex-auto text-right">
					<button
						type="button"
						className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-black bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
						onClick={handleShowCreate}
					>
						Create User
						<PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
					</button>
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mb-5">
						<form className="grid lg:grid-cols-5 md:grid-cols-4 gap-2" onSubmit={handleFilter}>
							<div className="col-span-1">
								<label htmlFor="email" className="block text-sm font-medium text-gray-700">
									Email
								</label>
								<input
									type="text"
									name="email"
									id="email"
									onChange={handleChange}
									className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
								/>
							</div>
							<div className="col-span-1">
								<label htmlFor="email" className="block text-sm font-medium text-gray-700">
									Name
								</label>
								<input
									type="text"
									name="name"
									id="name"
									onChange={handleChange}
									className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
								/>
							</div>

							<div className="col-span-1 relative">
								<button
									type="submit"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto sm:text-sm absolute bottom-0"
								>
									Search
								</button>
							</div>
						</form>
					</div>
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
										>
											Email
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											First Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Last Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Active
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Details</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y-2">
									{users.map(user => (
										<tr
											className={!check_user(user) ? "text-red-800 border-red-300 bg-red-50" : ""}
											key={user.id}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
												<div className="flex items-center">
													<div className="">
														<div className="font-medium">{user.email}</div>
													</div>
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{user.first_name}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{user.last_name}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">
													{user.active === true && (
														<CheckIcon className="active-icon" style={{ height: 25 }} />
													)}
													{user.active === false && (
														<XIcon className="inactive-icon" style={{ height: 25 }} />
													)}
												</div>
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
												<span className="ml-4 space-x-4">
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowAssign(user.id);
														}}
													>
														Assign User
													</button>
													<span className="" aria-hidden="true">
														|
													</span>
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowDetails(user.id);
														}}
													>
														Details
													</button>

													<span className="" aria-hidden="true">
														|
													</span>
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowDelete(user.id, user.email);
														}}
													>
														Delete
													</button>
												</span>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
