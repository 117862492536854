import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getContent, getContentWithoutState, postContent } from "../../../helpers/FetchContent";
import SpinnerSmall from "../../SpinnerSmall";
import ErrorMsgComponent from "../../../helpers/Errors";
import { useCustomerStore } from "../../../stores/customers-store";
import { displayApiError } from "../../../helpers/DisplayAPIError";

export default function AssignCustomer(props) {
	const setCustomers = useCustomerStore(state => state.setCustomers);
	const [errMsg, setErrMsg] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [unassignedArray, setUnassignedArray] = useState([]);
	const [loading, setLoading] = useState(false);
	const [formData, updateFormData] = useState({
		user_id: "loading...",
		customer_id: props.customer_id
	});
	const [assignedUsers, setAssignedUsers] = useState([]);

	const getAssignedCustomers = async () => {
		await getContent(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/users/connected?customer_id=${props.customer_id}`,
			setAssignedUsers
		);
	};

	const getCustomers = async () => {
		await getContent(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/customers/`, setCustomers);
	};

	const validateForm = () => {
		if (formData.user_id > 0 && typeof formData.user_id === "number") {
			return { isValid: true, error: "" };
		}

		return { isValid: false, error: "Please select a user" };
	};

	const getUnassignedUsers = async () => {
		const users = await getContentWithoutState(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/users/unassigned`
		);
		setUnassignedArray(users);
		setIsLoading(false);
	};

	useEffect(() => {
		if (props.open && props.customer_id) {
			getAssignedCustomers();
			getUnassignedUsers();
		}
	}, [props.open, props.customer_id]);

	const cancelButtonRef = useRef(null);

	const handleChange = async e => {
		await updateFormData({
			...formData,
			[e.target.name]: Number(e.target.value.trim()),
			customer_id: props.customer_id
		});
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		setErrMsg("");

		const validationData = await validateForm();

		if (validationData.isValid) {
			const response = await postContent(
				process.env.REACT_APP_NEXT_PUBLIC_API_URL + "/v1/users/assign",
				formData
			);
			if (response.ok) {
				await getCustomers();
				props.onClose();
			} else {
				// Show endpoint error if exists
				const err = await response.json();
				setErrMsg(err.error ? displayApiError(err.error) : "Unable to assign user to customer");
			}
		} else {
			console.log({ formData });
			setErrMsg(validationData.error);
		}

		setLoading(false);
	};

	return (
		<Transition.Root show={props.open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				onClose={props.onClose}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-6xl md:w-full">
							<div className="bg-white px-4 pt-5 pb-4 md:p-16 md:pb-14">
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
										Assign Customer
									</Dialog.Title>

									<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mb-6 w-full">
										<div className="md:grid md:grid-cols-3 md:gap-6">
											<div className="md:col-span-1">
												<p className="mt-1 text-sm text-gray-500">
													User(s) assigned to customer
												</p>
											</div>
											<div className="mt-5 md:mt-0 md:col-span-2">
												<table className="divide-y divide-gray-300 w-full" id="assigned_users">
													<thead className="bg-gray-50">
														<tr>
															<th
																scope="col"
																className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
															>
																Name
															</th>
															<th
																scope="col"
																className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
															>
																Email
															</th>
														</tr>
													</thead>

													<tbody className="divide-y-2">
														{assignedUsers.length === 0 && (
															<tr key="none">
																<td
																	className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6 text-center"
																	colSpan={2}
																>
																	<div className="font-medium">No Assigned User</div>
																</td>
															</tr>
														)}
														{assignedUsers.map(user => (
															<tr key={!user.id ? "none" : user.id}>
																<td className="whitespace-nowrap px-3 py-4 text-xs">
																	{user.first_name} {user.last_name}
																</td>
																<td className="whitespace-nowrap px-3 py-4 text-xs">
																	{user.email}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>

									<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
										<div className="md:grid md:grid-cols-3 md:gap-6">
											<div className="md:col-span-1">
												<p className="mt-1 text-sm text-gray-500">
													Please assign the user to a customer
												</p>
											</div>
											<div className="mt-5 md:mt-0 md:col-span-2">
												<form action="#" method="POST">
													<div className="grid grid-cols-6 gap-6">
														<div className="col-span-6">
															<label
																htmlFor="user_id"
																className="block text-sm font-medium text-gray-700"
															>
																Unassigned Users
															</label>
															<select
																id="user_id"
																name="user_id"
																onChange={handleChange}
																className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
															>
																<option key="default" value="">
																	Select User
																</option>
																{unassignedArray.map(
																	unassigned =>
																		unassigned.level < 100 && (
																			<option
																				key={unassigned.id}
																				value={unassigned.id}
																			>
																				{unassigned.email}
																			</option>
																		)
																)}
															</select>
														</div>
													</div>
												</form>
												{errMsg && <ErrorMsgComponent errMsg={errMsg} />}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={handleSubmit}
								>
									{loading && <SpinnerSmall />}
									{!loading && "Assign"}
								</button>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={props.onClose}
									ref={cancelButtonRef}
								>
									Cancel
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
