import React from "react";
import Base from "../components/Base";
import setNavigation from "../helpers/SetNavigation";

export default function Contracts() {

	const navigation = setNavigation("Contracts");


	return <Base navigation={navigation} crumb={"Contracts"} />;
}
