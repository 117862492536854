import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getContent } from "../../../helpers/FetchContent";

export default function ContractCustomer(props) {
	const [contractsArray, setContractsArray] = useState([]);
	const [plansArray, setPlansArray] = useState([]);

	function find_associated_plan_name(plan_id, plans) {
		if (plans.length > 0) {
			const result = plans.find(plan => plan.id === plan_id);
			return result.plan_name;
		}
		return "";
	}

	useEffect(() => {
		if (props.open && props.customer_id) {
			getContent(
				`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/contracts/customer/` + props.customer_id,
				setContractsArray
			);
		}
	}, [props.open, props.customer_id]);

	useEffect(() => {
		if (contractsArray.length > 0) {
			getContent(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/plans/`, setPlansArray);
		}
	}, [contractsArray]);

	const cancelButtonRef = useRef(null);

	return (
		<>
			<Transition.Root show={props.open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={props.onClose}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-6xl md:w-full">
								<div className="bg-white px-4 pt-5 pb-4 md:p-16 md:pb-14">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											See Contracts
										</Dialog.Title>
										<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
											<div className="md:grid md:grid-cols-1">
												<div className="mt-5 md:mt-0">
													<table className="min-w-full divide-y divide-gray-300 w-100">
														<thead className="bg-gray-50">
															<tr>
																<th
																	scope="col"
																	className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
																>
																	Contract No.
																</th>
																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
																>
																	Plan
																</th>
																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
																>
																	Start Date
																</th>
																<th
																	scope="col"
																	className="px-3 py-3.5 text-center text-xs font-semibold text-gray-900"
																>
																	Details
																</th>
															</tr>
														</thead>
														<tbody className="divide-y-2">
															{contractsArray.length === 0 && (
																<tr key="none">
																	<td
																		className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6"
																		colSpan={4}
																	>
																		<div className="text-center items-center">
																			<div className="">
																				<div className="font-medium">
																					No Contracts
																				</div>
																			</div>
																		</div>
																	</td>
																</tr>
															)}
															{contractsArray.map(contract => (
																<tr key={!contract.id ? "none" : contract.id}>
																	<td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
																		<div className="flex items-center">
																			<div className="">
																				{!contract.id && (
																					<div className="font-medium">
																						No Contracts
																					</div>
																				)}
																				<div className="font-medium">
																					{contract.contract_id}
																				</div>
																			</div>
																		</div>
																	</td>
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		<div className="">
																			{find_associated_plan_name(
																				contract.plan_id,
																				plansArray
																			)}
																		</div>
																	</td>
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		<div className="">{contract.start_date}</div>
																	</td>
																	<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
																		<span className="ml-4 space-x-4"></span>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={props.onClose}
										ref={cancelButtonRef}
									>
										Back
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
