const getTitle =  (titles, key) => {
	let title = "";

	try {
		if (titles) {
			const newTitle = titles.find(val => val.language === key )
			title = newTitle.title;
		}
	} catch (error) {
		console.log(`Failed to load ${key} title.`, error)
	}

	return title;
}

export { getTitle };
