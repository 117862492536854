import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";
import { useCommonStore } from "../stores/common-store";
import { getContent } from "../helpers/FetchContent";
import { useAffiliatePerformanceStore } from "../stores/affiliate-performance-store";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import ReferredCustomers from "./Modals/Affiliate/ReferredCustomers";
import { formatDateTime } from "../helpers/DateTime";

export default function SalesPerformanceListDetails() {
	const setOverallStats = useAffiliatePerformanceStore(state => state.setOverallStats);
	const setIndividualStats = useAffiliatePerformanceStore(state => state.setIndividualStats);
	const order = useAffiliatePerformanceStore(state => state.order);
	const setOrder = useAffiliatePerformanceStore(state => state.setOrder);
	const overallStats = useAffiliatePerformanceStore(state => state.overallStats);
	const individualStats = useAffiliatePerformanceStore(state => state.individualStats);
	const isLoading = useCommonStore(state => state.isLoading);
	const setIsLoading = useCommonStore(state => state.setIsLoading);
	
	const [selectedUserId, setSelectedUserId] = useState(0);
	const [showReferredCustomers, setShowReferredCustomers] = useState(false);

	useEffect(() => {
		getOverallStats();
		getIndividualStats();
	}, []);

	useEffect(() => {
		getIndividualStats();
	}, [order]);

	const handleShowReferredCustomers = userId => {
		setSelectedUserId(userId);
		setShowReferredCustomers(true);
	};

	const closeReferredCustomers = () => setShowReferredCustomers(false);

	const getOverallStats = async () => {
		await getContent(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/stats/affiliates`, setOverallStats);
		setIsLoading(false);
	};

	const getIndividualStats = async () => {
		await getContent(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/stats/affiliates/individual?order_by=${order.by}&order=${order.sort}`,
			setIndividualStats
		);
		setIsLoading(false);
	};

	const handleSort = (by, sort) => {
		setOrder({
			by,
			sort
		});
	};

	const getSort = by => (order.by === by && order.sort === "desc" ? "asc" : "desc");

	if (!overallStats || !individualStats || isLoading) {
		return <Spinner />;
	}

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Affiliate Performance</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all our Affiliate Performance.</p>
				</div>
			</div>

			<ReferredCustomers open={showReferredCustomers} user_id={selectedUserId} onClose={closeReferredCustomers} />

			<div className="mt-8 flex flex-col">
				<h2 className="text-l font-semibold text-gray-900">Overall Performance</h2>

				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-3">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
										>
											<span className="sr-only">Details</span>
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-center text-xs font-semibold text-gray-900"
										>
											Numbers
										</th>
									</tr>
								</thead>
								<tbody className="divide-y-2">
									<tr>
										<td className="whitespace-nowrap px-3 py-4 text-xs">
											Total customers acquired via the Affiliate Program
										</td>
										<td className="text-center px-3 py-4 text-xs">
											{overallStats.customer_referred_count}
										</td>
									</tr>
									<tr>
										<td className="whitespace-nowrap px-3 py-4 text-xs">
											Total hashrate reward in the past 30 days
										</td>
										<td className="text-center px-3 py-4 text-xs">{overallStats.hr_rewards}</td>
									</tr>
									<tr>
										<td className="whitespace-nowrap px-3 py-4 text-xs">
											Total customers using the Affiliate Links
										</td>
										<td className="text-center px-3 py-4 text-xs">
											{overallStats.customer_referrer_count}
										</td>
									</tr>
									<tr>
										<td className="whitespace-nowrap px-3 py-4 text-xs">
											Total customers who were acquired via the Affiliate Program but cancelled
											the subscription
										</td>
										<td className="text-center px-3 py-4 text-xs">
											{overallStats.cancelled_customer_referred_count}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-8 flex flex-col">
				<h2 className="text-l font-semibold text-gray-900">Individual Performance</h2>

				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-3">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 align-middle cursor-pointer"
											onClick={() => handleSort("customer_id", getSort("customer_id"))}
										>
											<span className="inline-block align-middle">ID</span>
											<span className="float-right inline-block align-middle h-1">
												{order.by !== "customer_id" ? (
													<>
														<ChevronUpIcon className="h-3 w-3" />
														<ChevronDownIcon className="h-3 w-3" />
													</>
												) : order.sort.toLowerCase() === "desc" ? (
													<ChevronDownIcon className="h-3 w-3 mt-1" />
												) : (
													<ChevronUpIcon className="h-3 w-3 mt-1" />
												)}
											</span>
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
											onClick={() => handleSort("customer_name", getSort("customer_name"))}
										>
											<span className="inline-block align-middle">Name</span>
											<span className="float-right inline-block align-middle h-1">
												{order.by !== "customer_name" ? (
													<>
														<ChevronUpIcon className="h-3 w-3" />
														<ChevronDownIcon className="h-3 w-3" />
													</>
												) : order.sort.toLowerCase() === "desc" ? (
													<ChevronDownIcon className="h-3 w-3 mt-1" />
												) : (
													<ChevronUpIcon className="h-3 w-3 mt-1" />
												)}
											</span>
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
											onClick={() => handleSort("customer_email", getSort("customer_email"))}
										>
											<span className="inline-block align-middle">Email</span>
											<span className="float-right inline-block align-middle h-1">
												{order.by !== "customer_email" ? (
													<>
														<ChevronUpIcon className="h-3 w-3" />
														<ChevronDownIcon className="h-3 w-3" />
													</>
												) : order.sort.toLowerCase() === "desc" ? (
													<ChevronDownIcon className="h-3 w-3 mt-1" />
												) : (
													<ChevronUpIcon className="h-3 w-3 mt-1" />
												)}
											</span>
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
											onClick={() =>
												handleSort(
													"acquired_customer_count",
													getSort("acquired_customer_count")
												)
											}
										>
											<div className="flex">
												<span className="inline-block align-middle flex-1 whitespace-nowrap">
													Acquired Customers
												</span>
												<span className="float-right inline-block align-middle h-1 ml-3 text-right flex-none">
													{order.by !== "acquired_customer_count" ? (
														<>
															<ChevronUpIcon className="h-3 w-3" />
															<ChevronDownIcon className="h-3 w-3" />
														</>
													) : order.sort.toLowerCase() === "desc" ? (
														<ChevronDownIcon className="h-3 w-3 mt-1" />
													) : (
														<ChevronUpIcon className="h-3 w-3 mt-1" />
													)}
												</span>
											</div>
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
											onClick={() =>
												handleSort(
													"ongoing_hash_rate_reward",
													getSort("ongoing_hash_rate_reward")
												)
											}
										>
											<div className="flex">
												<span className="inline-block align-middle flex-1 whitespace-nowrap">
													Hashrate Reward
												</span>
												<span className="float-right inline-block align-middle h-1 ml-3 text-right">
													{order.by !== "ongoing_hash_rate_reward" ? (
														<>
															<ChevronUpIcon className="h-3 w-3" />
															<ChevronDownIcon className="h-3 w-3" />
														</>
													) : order.sort.toLowerCase() === "desc" ? (
														<ChevronDownIcon className="h-3 w-3 mt-1" />
													) : (
														<ChevronUpIcon className="h-3 w-3 mt-1" />
													)}
												</span>
											</div>
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
											onClick={() =>
												handleSort(
													"first_customer_registration_date",
													getSort("first_customer_registration_date")
												)
											}
										>
											<div>
												<span className="inline-block align-middle flex-1 whitespace-nowrap">
													Acquisition Time
												</span>
												<span className="float-right inline-block align-middle h-1 ml-3 text-right flex-none">
													{order.by !== "first_customer_registration_date" ? (
														<>
															<ChevronUpIcon className="h-3 w-3" />
															<ChevronDownIcon className="h-3 w-3" />
														</>
													) : order.sort.toLowerCase() === "desc" ? (
														<ChevronDownIcon className="h-3 w-3 mt-1" />
													) : (
														<ChevronUpIcon className="h-3 w-3 mt-1" />
													)}
												</span>
											</div>
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
											onClick={() =>
												handleSort("registration_date", getSort("registration_date"))
											}
										>
											<div>
												<span className="inline-block align-middle flex-1 whitespace-nowrap">
													Registration Date
												</span>
												<span className="float-right inline-block align-middle h-1 ml-3 text-right flex-none">
													{order.by !== "registration_date" ? (
														<>
															<ChevronUpIcon className="h-3 w-3" />
															<ChevronDownIcon className="h-3 w-3" />
														</>
													) : order.sort.toLowerCase() === "desc" ? (
														<ChevronDownIcon className="h-3 w-3 mt-1" />
													) : (
														<ChevronUpIcon className="h-3 w-3 mt-1" />
													)}
												</span>
											</div>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y-2">
									{individualStats.map(stat => (
										<tr
											key={stat.customer_id}
											className="cursor-pointer"
											onClick={() => handleShowReferredCustomers(stat.customer_id)}
										>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.customer_id}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.customer_name}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.customer_email}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.acquired_customer_count}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.ongoing_hash_rate_reward}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.first_customer_registration_date ? formatDateTime(stat.first_customer_registration_date) : ''}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{formatDateTime(stat.registration_date)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
