import React, { useState, useEffect } from "react";
import Base from "../components/Base";
import setNavigation from "../helpers/SetNavigation";
import { getContentWithoutState } from "../helpers/FetchContent";
import { useCommonStore } from "../stores/common-store";

export default function Plans() {
	const setIsLoading = useCommonStore(state => state.setIsLoading);
	const [plansInfo, setPlansInfo] = useState(null);
	const [data, setData] = useState([]);

	const navigation = setNavigation("Plans");

	const getPlans = async () => {
		await setIsLoading(true);
		const plans = await getContentWithoutState(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/plans/`);
		await setPlansInfo(plans);
		await setIsLoading(false);
	};

	useEffect(() => {
		if (localStorage.getItem("token") && plansInfo === null) {
			getPlans();
		}
		if (plansInfo && data.length < 1) {
			setData(plansInfo);
		}
	}, [plansInfo, data]);

	return <Base navigation={navigation} crumb={"Plans"} data={data} />;
}
