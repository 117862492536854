import React, { useState, useEffect } from "react";
import Base from "../components/Base";
import setNavigation from "../helpers/SetNavigation";
import { getContentWithoutState } from "../helpers/FetchContent";
import { useCommonStore } from "../stores/common-store";

export default function Customers() {
	const setIsLoading = useCommonStore(state => state.setIsLoading);
	const [customerInfo, setCustomerInfo] = useState(null);
	const [data, setData] = useState([]);

	const navigation = setNavigation("Customers");

	const getCustomers = async () => {
		await setIsLoading(true);
		const customers = await getContentWithoutState(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/customers/`,
		);
		await setCustomerInfo(customers);
		await setIsLoading(false);
	};

	useEffect(() => {
		if (localStorage.getItem("token") && customerInfo === null) {
			getCustomers();
		}
		if (customerInfo && data.length < 1) {
			setData(customerInfo);
		}
	}, [customerInfo, data]);

	return <Base navigation={navigation} crumb={"Customers"} data={data} />;
}
