const isEmailValid = email => {
	// Regular expression to validate email format
	const emailRegex = /^[\w\+.-]+@([\w-]+\.)+[\w-]{2,}$/;
	return emailRegex.test(email);
};

const validateCustomerForm = formData => {
	let errors = {};

	if (formData.name <= 0) {
		errors.name = "Customer name required";
	}

	if (!isEmailValid(formData.email)) {
		errors.email = "Please enter a valid email";
	}

	if (formData.details <= 0) {
		errors.details = "Details required";
	}

	if (formData.iban <= 0) {
		errors.iban = "IBAN required";
	}

	if (formData.phone <= 0) {
		errors.phone = "Phone number required";
	}

	if (formData.address <= 0) {
		errors.address = "Address required";
	}

	if (formData.city <= 0) {
		errors.city = "City required";
	}

	if (formData.zip <= 0) {
		errors.zip = "Postal code required";
	}

	if (formData.contact <= 0) {
		errors.contact = "Contact name required";
	}

	return errors;
};

const validateUserForm = (formData, isEdit) => {
	let errors = {};

	if (formData.first_name <= 0) {
		errors.first_name = "First name required";
	}

	if (formData.last_name <= 0) {
		errors.last_name = "Last name required";
	}

	if (!isEmailValid(formData.email) && !isEdit) {
		errors.email = "Please enter a valid email";
	}

	const passwordRegex =
		/^(?=.*[A-Z])(?=.*\d)(?=.*[:!@#$%^&*[\]{}()?"\\,><';|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,}$/;

	if (!passwordRegex.test(formData.password) && !isEdit) {
		errors.password =
			"Password must contain at least 8 characters, 1 uppercase, 1 special Character, and 1 number.";
	}

	if (formData.level <= 0) {
		errors.level = "Level required";
	}

	return errors;
};

export { isEmailValid, validateCustomerForm, validateUserForm };
