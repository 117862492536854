import { create } from "zustand";

export const useSalesPerformanceStore = create(set => ({
	stats: [],
	setStats: stats => set({ stats }),
	filters: {
		name: "",
		email: ""
	},
	setFilters: filters => set({filters})
}));
