import React from "react";
import { Redirect, Switch, Route, Router, useHistory } from "react-router-dom";

import "./css/App.css";
import { SignIn, Dashboard, Customers, Users, Contracts, Plans, ContentVideos, SalesPerformance } from "./sites/Sites";
import RouteGuard from "./helpers/RouteGuard";
import AffiliatePerformance from "./sites/AffiliatePerformance";

const App = () => {
	const history = useHistory();

	return (
		<Router history={history}>
			<Switch>
				<RouteGuard exact path="/" component={Dashboard} />
				<RouteGuard exact path="/users/" component={Users} />
				<RouteGuard exact path="/customers/" component={Customers} />
				<RouteGuard exact path="/affiliate-performance/" component={AffiliatePerformance} />
				<RouteGuard exact path="/sales-performance/" component={SalesPerformance} />
				<RouteGuard exact path="/contracts/" component={Contracts} />
				<RouteGuard exact path="/plans/" component={Plans} />
				<RouteGuard exact path="/content_videos/" component={ContentVideos} />
				<Route path="/login" component={SignIn} />
				<Redirect to="/" />
			</Switch>
		</Router>
	);
};

export default App;
