import { create } from "zustand";

export const useContractStore = create(set => ({
	contracts: [],
	setContracts: contracts => set({ contracts }),
	filters: {
		name: ""
	},
	setFilters: filters => set({ filters })
}));
