export const API_ERRORS = [
	{
		error: "INVALID_CREDENTIALS",
		display: "Invalid Credentials"
	},
	{
		error: "UNAUTHORIZED_USER",
		display: "Unauthorized user"
	},
	{
		error: "FAILED_TO_SEND_EMAIL",
		display: "Failed to send email"
	},
	{
		error: "MALFORMED_REQUEST_BODY",
		display: "Invalid form data"
	},
	{
		error: "USER_ALREADY_EXISTS",
		display: "User already exist"
	},
	{
		error: "INSUFFICIENT_ACCESS_RIGHTS",
		display: "User level access not met"
	},
	{
		error: "ACCESS_DENIED",
		display: "Access denied"
	},
	{
		error: "USER_NOT_FOUND",
		display: "User not found"
	},
	{
		error: "CUSTOMER_NOT_FOUND",
		display: "Customer not found"
	},
	{
		error: "EMAIL_ALREADY_TAKEN",
		display: "Email already taken"
	},
	{
		error: "INVALID_EMAIL_FORMAT",
		display: "Invalid email format"
	},
	{
		error: "INVALID_NAME_FORMAT",
		display: "Invalid name format"
	},
	{
		error: "STRING_FORMAT_NOT_ALLOWED",
		display: "String format not allowed"
	},
	{
		error: "EMAIL_BODY_REQUIRED",
		display: "Email body required"
	},
	{
		error: "USER_CUSTOMER_ASSIGNMENT_ALREADY_EXISTS",
		display: "Customer already assigned to user"
	},
	{
		error: "WALLET_NOT_FOUND",
		display: "Wallet not found"
	},
	{
		error: "NO_WALLET_ASSIGNED_TO_USER",
		display: "No wallet assigned to user"
	},
	{
		error: "PLAN_NOT_FOUND",
		display: "Plan not found"
	},
	{
		error: "CONTRACT_NOT_FOUND",
		display: "Contract not found"
	},
	{
		error: "TOO_MANY_ROWS_TO_DELETE",
		display: "Too many rows to delete"
	},
	{
		error: "NO_CUSTOMER_ASSIGNED_TO_USER",
		display: "No customer assigned to user"
	},
	{
		error: "REFERRAL_CODE_NOT_FOUND",
		display: "Invalid referral code"
	},
	{
		error: "AFFILIATE_PLAN_ALREADY_LINKED_TO_ANOTHER_PLAN",
		display: "Affiliate plan already linked to another plan"
	},
	{
		error: "CUSTOMER_NOT_ASSIGNED_TO_A_USER",
		display: "Customer not assigned to a user"
	}
];
