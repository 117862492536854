import React, { useEffect } from "react";
import Spinner from "./Spinner";
import { useCommonStore } from "../stores/common-store";
import { getContent } from "../helpers/FetchContent";
import { useSalesPerformanceStore } from "../stores/sales-performance-store";
import { formatDateTime } from "../helpers/DateTime";

export default function SalesPerformanceListDetails() {
	const setStats = useSalesPerformanceStore(state => state.setStats);
	const filters = useSalesPerformanceStore(state => state.filters);
	const setFilters = useSalesPerformanceStore(state => state.setFilters);
	const stats = useSalesPerformanceStore(state => state.stats);
	const isLoading = useCommonStore(state => state.isLoading);
	const setIsLoading = useCommonStore(state => state.setIsLoading);

	useEffect(() => {
		getStats();
	}, []);

	const handleFilter = async e => {
		e.preventDefault();
		getStats();
	};

	const getStats = async () => {
		await getContent(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/stats/sales`, setStats);
		setIsLoading(false);
	};

	const handleChange = e => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value
		});
	};

	if (!stats || isLoading) {
		return <Spinner />;
	}

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Sales Performance</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all our Sales Performance.</p>
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="col-span-1">
					<h2 className="text-l font-semibold text-gray-900">Customer Information</h2>
				</div>

				{/* <form className="grid grid-cols-2" onSubmit={handleFilter}>
					
					<div className="col-span-1">
						<input
							type="text"
							name="email"
							id="email"
							onChange={handleChange}
							className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block float-right shadow-sm sm:text-sm border-gray-300 rounded-md"
						/>
					</div>
				</form> */}

				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-3">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
										>
											ID
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Email
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Registration Date
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Introducing Customer's ID
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Active Packages
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Active Hashrates
										</th>
									</tr>
								</thead>
								<tbody className="divide-y-2">
									{stats.map(stat => (
										<tr key={stat.customer_id}>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.customer_id}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.customer_email}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.customer_name}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{formatDateTime(stat.customer_created_at)}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.referrer_customer_id || ''}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.active_package_count}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												{stat.active_hash_rate_total}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
