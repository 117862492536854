import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";
import CreateContract from "./Modals/ContractsList/CreateContract";
import DeleteContract from "./Modals/ContractsList/DeleteContract";
import { useCommonStore } from "../stores/common-store";
import { getContent } from "../helpers/FetchContent";
import { useContractStore } from "../stores/contracts-store";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

export default function ContractsListDetails() {
	const isLoading = useCommonStore(state => state.isLoading);
	const setIsLoading = useCommonStore(state => state.setIsLoading);
	const contracts = useContractStore(state => state.contracts);
	const setContracts = useContractStore(state => state.setContracts);
	const filters = useContractStore(state => state.filters);
	const setFilters = useContractStore(state => state.setFilters);

	const reload = () => window.location.reload();

	useEffect(() => {
		getContracts();
	}, []);

	const handleChange = e => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value
		});
	};

	const handleFilter = async e => {
		e.preventDefault();
		getContracts();
	};

	const [selectedContractId, setSelectedContractId] = useState(0);
	const [selectedContractUuid, setSelectedContractUuid] = useState(0);
	const [showDelete, setShowDelete] = useState(false);

	const handleShowDelete = (contractId, contractUuid) => {
		setSelectedContractId(contractId);
		setShowDelete(true);
		setSelectedContractUuid(contractUuid);
	};

	const closeDeactivateDialog = () => setShowDelete(false);

	const getContracts = async () => {
		await getContent(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/contracts/joined?name=${filters.name}`,
			setContracts
		);
		setIsLoading(false);
	};

	if (!contracts || isLoading) {
		return <Spinner />;
	}
	return (
		<div className="px-4 sm:px-6 lg:px-8">
			{/* Modals [start] */}
			<DeleteContract
				open={showDelete}
				contract_id={selectedContractId}
				uuid={selectedContractUuid}
				onClose={closeDeactivateDialog}
			/>
			{/* Modals [end] */}
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Contracts</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all our Contracts.</p>
				</div>
				<div className="sm:flex-auto text-right">
					<CreateContract onClose={reload} />
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mb-5">
						<form className="grid lg:grid-cols-5 md:grid-cols-4 gap-2" onSubmit={handleFilter}>
							<div className="col-span-1">
								<label htmlFor="email" className="block text-sm font-medium text-gray-700">
									Customer
								</label>
								<input
									type="text"
									name="name"
									id="name"
									onChange={handleChange}
									className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
								/>
							</div>

							<div className="col-span-1 relative">
								<button
									type="submit"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto sm:text-sm absolute bottom-0"
								>
									Search
								</button>
							</div>
						</form>
					</div>
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
										>
											Customer
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Contract No.
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Contract Type
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Start Date
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Active
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Details</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y-2">
									{contracts.map(contract => (
										<tr
											key={contract.id}
											className={!contract.active ? "text-red-800 border-red-300 bg-red-50" : ""}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
												<div className="flex items-center">
													<div className="">
														<div className="font-medium">{contract.customer.name}</div>
													</div>
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{contract.contract_id}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{contract.plan.plan_name}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{contract.start_date}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">
													{contract.active === true && (
														<CheckIcon className="active-icon" style={{ height: 25 }} />
													)}
													{contract.active === false && (
														<XIcon className="inactive-icon" style={{ height: 25 }} />
													)}
												</div>
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
												{contract.active && (
													<span className="ml-4 space-x-4">
														<button
															type="button"
															className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
															onClick={() => {
																handleShowDelete(contract.id, contract.contract_id);
															}}
														>
															Deactivate
														</button>
													</span>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
