import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getContent, getContentWithoutState, postContent } from "../../../helpers/FetchContent";
import SpinnerSmall from "../../SpinnerSmall";
import ErrorMsgComponent from "../../../helpers/Errors";
import { useCustomerStore } from "../../../stores/customers-store";
import { displayApiError } from "../../../helpers/DisplayAPIError";

import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { formatDateTime } from "../../../helpers/DateTime";

export default function ReferredCustomers(props) {
	const [errMsg, setErrMsg] = useState("");
	const [loading, setLoading] = useState(false);
	const [customers, setCustomers] = useState([]);
	const [order, setOrder] = useState({
		by: "registration_date",
		sort: "desc"
	});

	const getReferredCustomers = async () => {
		await getContent(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/stats/affiliates/referred/${props.user_id}?order_by=${order.by}&order=${order.sort}`,
			setCustomers
		);
	};

	const handleSort = (by, sort) => {
		setOrder({
			by,
			sort
		});
	};

	const getSort = by => (order.by === by && order.sort === "desc" ? "asc" : "desc");

	useEffect(() => {
		getReferredCustomers();
	}, [order]);

	useEffect(() => {
		if (props.open && props.user_id) {
			setOrder({
				by: "registration_date",
				sort: "desc"
			});
			getReferredCustomers();
		}
	}, [props.user_id]);

	const cancelButtonRef = useRef(null);

	return (
		<Transition.Root show={props.open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				onClose={props.onClose}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-6xl md:w-full">
							<div className="bg-white px-4 pt-5 pb-4 md:p-16 md:pb-14">
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
										Referred Customer(s)
									</Dialog.Title>

									<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mb-6 w-full">
										<div className="overflow-x-auto">
											<div className="inline-block min-w-full py-1 px-1 align-middle">
												<h2 className="text-gray-600 font-light text-md">Customer ID</h2>
												<h1 className="font-string text-lg mb-3">{props.user_id}</h1>
												<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
													<table
														className="divide-y divide-gray-300 w-full"
														id="assigned_users"
													>
														<thead className="bg-gray-50">
															<tr>
																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
																	onClick={() =>
																		handleSort(
																			"customer_id",
																			getSort("customer_id")
																		)
																	}
																>
																	<span className="inline-block align-middle">
																		ID
																	</span>
																	<span className="float-right inline-block align-middle h-1">
																		{order.by !== "customer_id" ? (
																			<>
																				<ChevronUpIcon className="h-3 w-3" />
																				<ChevronDownIcon className="h-3 w-3" />
																			</>
																		) : order.sort.toLowerCase() === "desc" ? (
																			<ChevronDownIcon className="h-3 w-3 mt-1" />
																		) : (
																			<ChevronUpIcon className="h-3 w-3 mt-1" />
																		)}
																	</span>
																</th>

																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
																	onClick={() =>
																		handleSort(
																			"customer_name",
																			getSort("customer_name")
																		)
																	}
																>
																	<span className="inline-block align-middle">
																		Name
																	</span>
																	<span className="float-right inline-block align-middle h-1">
																		{order.by !== "customer_name" ? (
																			<>
																				<ChevronUpIcon className="h-3 w-3" />
																				<ChevronDownIcon className="h-3 w-3" />
																			</>
																		) : order.sort.toLowerCase() === "desc" ? (
																			<ChevronDownIcon className="h-3 w-3 mt-1" />
																		) : (
																			<ChevronUpIcon className="h-3 w-3 mt-1" />
																		)}
																	</span>
																</th>

																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
																	onClick={() =>
																		handleSort(
																			"customer_email",
																			getSort("customer_email")
																		)
																	}
																>
																	<span className="inline-block align-middle">
																		Email
																	</span>
																	<span className="float-right inline-block align-middle h-1">
																		{order.by !== "customer_email" ? (
																			<>
																				<ChevronUpIcon className="h-3 w-3" />
																				<ChevronDownIcon className="h-3 w-3" />
																			</>
																		) : order.sort.toLowerCase() === "desc" ? (
																			<ChevronDownIcon className="h-3 w-3 mt-1" />
																		) : (
																			<ChevronUpIcon className="h-3 w-3 mt-1" />
																		)}
																	</span>
																</th>

																{/* <th
														scope="col"
														className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
														onClick={() =>
															handleSort(
																"acquired_customer_count",
																getSort("acquired_customer_count")
															)
														}
													>
														<span className="inline-block align-middle">
															Acquired Customers No.
														</span>
														<span className="float-right inline-block align-middle h-1">
															{order.by !== "acquired_customer_count" ? (
																<>
																	<ChevronUpIcon className="h-3 w-3" />
																	<ChevronDownIcon className="h-3 w-3" />
																</>
															) : order.sort.toLowerCase() === "desc" ? (
																<ChevronDownIcon className="h-3 w-3 mt-1" />
															) : (
																<ChevronUpIcon className="h-3 w-3 mt-1" />
															)}
														</span>
													</th> */}

																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
																	onClick={() =>
																		handleSort(
																			"generated_hash_rate_reward",
																			getSort("generated_hash_rate_reward")
																		)
																	}
																>
																	<div className="flex">
																		<span className="inline-block align-middle whitespace-nowrap">
																			Hashrate Reward
																		</span>
																		<span className="float-right inline-block align-middle h-1 ml-3">
																			{order.by !==
																			"generated_hash_rate_reward" ? (
																				<>
																					<ChevronUpIcon className="h-3 w-3" />
																					<ChevronDownIcon className="h-3 w-3" />
																				</>
																			) : order.sort.toLowerCase() === "desc" ? (
																				<ChevronDownIcon className="h-3 w-3 mt-1" />
																			) : (
																				<ChevronUpIcon className="h-3 w-3 mt-1" />
																			)}
																		</span>
																	</div>
																</th>

																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
																	onClick={() =>
																		handleSort(
																			"registration_date",
																			getSort("registration_date")
																		)
																	}
																>
																	<span className="inline-block align-middle whitespace-nowrap">
																		Acquisition Time
																	</span>
																	<span className="float-right inline-block align-middle h-1">
																		{order.by !== "registration_date" ? (
																			<>
																				<ChevronUpIcon className="h-3 w-3" />
																				<ChevronDownIcon className="h-3 w-3" />
																			</>
																		) : order.sort.toLowerCase() === "desc" ? (
																			<ChevronDownIcon className="h-3 w-3 mt-1" />
																		) : (
																			<ChevronUpIcon className="h-3 w-3 mt-1" />
																		)}
																	</span>
																</th>

																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 cursor-pointer"
																	onClick={() =>
																		handleSort(
																			"cancellation_time",
																			getSort("cancellation_time")
																		)
																	}
																>
																	<div className="flex">
																		<span className="inline-block align-middle whitespace-nowrap">
																			Cancellation Time
																		</span>
																		<span className="float-right inline-block align-middle h-1 ml-3 ">
																			{order.by !== "cancellation_time" ? (
																				<>
																					<ChevronUpIcon className="h-3 w-3" />
																					<ChevronDownIcon className="h-3 w-3" />
																				</>
																			) : order.sort.toLowerCase() === "desc" ? (
																				<ChevronDownIcon className="h-3 w-3 mt-1" />
																			) : (
																				<ChevronUpIcon className="h-3 w-3 mt-1" />
																			)}
																		</span>
																	</div>
																</th>
															</tr>
														</thead>

														<tbody className="divide-y-2">
															{customers.length === 0 && (
																<tr key="none">
																	<td
																		className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6 text-center"
																		colSpan={6}
																	>
																		<div className="font-medium">
																			No Referred Customer
																		</div>
																	</td>
																</tr>
															)}
															{customers.map(customer => (
																<tr key={customer.customer_id}>
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		{customer.customer_id}
																	</td>
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		{customer.customer_name}
																	</td>
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		{customer.customer_email}
																	</td>
																	{/* <td className="whitespace-nowrap px-3 py-4 text-xs">
															{customer.acquired_customer_count}
														</td> */}
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		{customer.generated_hash_rate_reward}
																	</td>
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		{formatDateTime(customer.registration_date)}
																	</td>
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		{customer.cancellation_time
																			? formatDateTime(customer.cancellation_time)
																			: ""}
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={props.onClose}
									ref={cancelButtonRef}
								>
									Back
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
