import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { patchContent, getContent } from "../../../helpers/FetchContent";
import SpinnerSmall from "../../SpinnerSmall";
import { useContractStore } from "../../../stores/contracts-store";
import { displayApiError } from "../../../helpers/DisplayAPIError";
import ErrorMsgComponent from "../../../helpers/Errors";

export default function DeleteContract(props) {
	const [errMsg, setErrMsg] = useState("");
	const setContracts = useContractStore(state => state.setContracts);
	const filters = useContractStore(state => state.filters);
	const [loading, setLoading] = useState(false);
	const cancelButtonRef = useRef(null);

	const getContracts = async () => {
		await getContent(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/contracts/joined?name=${filters.name}`, setContracts);
	};

	const deleteContract = async e => {
		e.preventDefault();
		setLoading(true);
		setErrMsg("");

		const response = await patchContent(
			process.env.REACT_APP_NEXT_PUBLIC_API_URL + "/v1/contracts/deactivate/" + props.contract_id,
		);
		if (response.ok) {
			await getContracts();
			props.onClose();
		} else {
			// Show endpoint error if exists
			const err = await response.json();
			setErrMsg(err.error ? displayApiError(err.error) : "Unable to create user");
		}

		setLoading(false);
	};

	return (
		<>
			<Transition.Root show={props.open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={props.onClose}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div>
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-lg leading-6 font-medium text-gray-900"
											>
												Deactivate Contract {props.uuid}
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">This will deactivate the following Contract with ID:</p>
												<p className="text-sm text-gray-500">{props.uuid}</p>
											</div>
										</div>
									</div>
									{errMsg && <ErrorMsgComponent errMsg={errMsg} />}
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										id="delete_button"
										type="submit"
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={deleteContract}
									>
										{loading && <SpinnerSmall />}
										{!loading && "Deactivate"}
									</button>
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={props.onClose}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
