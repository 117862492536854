import { API_ERRORS } from "../constants/errors";

const displayApiError = error => {
	const filteredError = API_ERRORS.find(row => row.error === error);

	if (filteredError) {
		return filteredError.display;
	}

	return error;
};

export { displayApiError };
