import { create } from "zustand";

export const useAffiliatePerformanceStore = create(set => ({
	overallStats: [],
	setOverallStats: overallStats => set({ overallStats }),
	individualStats: [],
	setIndividualStats: individualStats => set({ individualStats }),
	order: {
		by: "",
		sort: ""
	},
	setOrder: order => set({order})
}));
