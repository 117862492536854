import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/solid";
import { getContent, postContent } from "../../../helpers/FetchContent";
import SpinnerSmall from "../../SpinnerSmall";
import ErrorMsgComponent from "../../../helpers/Errors";
import { usePlanStore } from "../../../stores/plans-store";
import { displayApiError } from "../../../helpers/DisplayAPIError";

export default function CreatePlan(props) {
	const setPlans = usePlanStore(state => state.setPlans);

	const [errMsg, setErrMsg] = useState("");
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const cancelButtonRef = useRef(null);
	const initialFormData = Object.freeze({
		plan_name: "",
		tera_hashes: 0,
		duration_days: 0
	});
	const [formData, updateFormData] = useState(initialFormData);
	const [formErrors, setFormErrors] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);

	const validateForm = () => {
		let errors = {};

		if (formData.plan_name <= 0) {
			errors.plan_name = "Plan name required";
		}
		if (formData.amount <= 0 || isNaN(formData.amount)) {
			errors.amount = "Amount should be a valid number";
		}
		if (formData.tera_hashes <= 0 || isNaN(formData.tera_hashes)) {
			errors.tera_hashes = "Tera hashes should be a valid number";
		}
		if (formData.duration_days <= 0 || isNaN(formData.duration_days)) {
			errors.duration_days = "Duration days should be a valid number";
		}

		return errors;
	};

	const handleChange = e => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const getPlans = async () => {
		await getContent(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/plans/`, setPlans);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		setFormErrors({});

		const errors = await validateForm();
		setIsSubmitted(true);

		if (Object.keys(errors).length === 0) {
			formData.duration_days = Number(formData.duration_days);
			formData.tera_hashes = Number(formData.tera_hashes);
			formData.amount = Number(formData.amount);
			const response = await postContent(process.env.REACT_APP_NEXT_PUBLIC_API_URL + "/v1/plans/", formData);
			if (response.ok) {
				// Refresh list upon successful creation
				await getPlans();
				setOpen(false);
			} else {
				// Show endpoint error if exists
				const err = await response.json();
				setErrMsg(err.error ? displayApiError(err.error) : "Unable to create plan");
			}
		} else {
			setFormErrors(errors);
		}

		setLoading(false);
	};

	useEffect(() => {
		if (open) {
			updateFormData(initialFormData);
		}
	}, [open]);

	const resetForm = () => {
		setOpen(false);
		setFormErrors({});
	};

	return (
		<>
			<button
				type="button"
				className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-black bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
				onClick={() => {
					setOpen(true);
				}}
			>
				Create Plan
				<PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
			</button>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={resetForm}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-6xl md:w-full">
								<div className="bg-white px-4 pt-5 pb-4 md:p-16 md:pb-14">
									<div className="lg:flex lg:items-start">
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-lg leading-6 font-medium text-gray-900"
											>
												Create Plan
											</Dialog.Title>
											<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
												<div className="md:grid md:grid-cols-3 md:gap-6">
													<div className="md:col-span-1">
														<p className="mt-1 text-sm text-gray-500">
															Please fill in the necessary information to create your new
															Plan
														</p>
													</div>
													<div className="mt-5 md:mt-0 md:col-span-2">
														<form action="#" method="POST">
															<div className="grid grid-cols-6 gap-6">
																<div className="col-span-3">
																	<label
																		htmlFor="plan_name"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Plan Name
																	</label>
																	<input
																		type="text"
																		name="plan_name"
																		id="plan_name"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.plan_name && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.plan_name && (
																		<span className="text-red-700 text-sm">
																			{formErrors.plan_name}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="amount"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Amount
																	</label>
																	<input
																		type="text"
																		name="amount"
																		id="amount"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.amount && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.amount && (
																		<span className="text-red-700 text-sm">
																			{formErrors.amount}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="tera_hashes"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Tera Hashes
																	</label>
																	<input
																		type="text"
																		name="tera_hashes"
																		id="tera_hashes"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.tera_hashes && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.tera_hashes && (
																		<span className="text-red-700 text-sm">
																			{formErrors.tera_hashes}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="duration_days"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Duration Days
																	</label>
																	<input
																		type="text"
																		name="duration_days"
																		id="duration_days"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.duration_days && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.duration_days && (
																		<span className="text-red-700 text-sm">
																			{formErrors.duration_days}
																		</span>
																	)}
																</div>
															</div>
														</form>
														{errMsg && <ErrorMsgComponent errMsg={errMsg} />}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={handleSubmit}
									>
										{loading && <SpinnerSmall />}
										{!loading && "Create"}
									</button>
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={resetForm}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
