import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getContent, getContentWithoutState, patchContent, postContent } from "../../../helpers/FetchContent";
import SpinnerSmall from "../../SpinnerSmall";
import CountySelector from "../../CountrySelector";
import { validateCustomerForm } from "../../../helpers/Validators";
import ErrorMsgComponent from "../../../helpers/Errors";
import { useCustomerStore } from "../../../stores/customers-store";
import { displayApiError } from "../../../helpers/DisplayAPIError";
import { customerCreateInitialData, customerUpdateInitialData } from "../../../constants/inits";

export default function CustomerForm(props) {
	const setCustomers = useCustomerStore(state => state.setCustomers);
	const [errMsg, setErrMsg] = useState("");
	const [loading, setLoading] = useState(false);
	const [formData, updateFormData] = useState(props.isEdit ? customerUpdateInitialData : customerCreateInitialData);
	const [formErrors, setFormErrors] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);

	const getCustomer = async () => {
		setErrMsg("");

		const response = await getContentWithoutState(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/customers/` + props.customer_id
		);

		if (response.id) {
			updateFormData({
				name: response.name,
				details: response.details,
				iban: response.iban,
				phone: response.phone,
				email: response.email,
				address: response.address,
				zip: response.zip,
				contact: response.contact,
				country: response.country,
				city: response.city,
				active: response.active,
				referral_code: response.referral_code
			});
			console.log({ response });
		} else {
			setErrMsg("Unable to fetch customer");
		}
	};

	const getCustomers = async () => {
		await getContent(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/customers/`, setCustomers);
	};

	useEffect(() => {
		if (props.open && props.customer_id && props.isEdit) {
			getCustomer();
		}

		if (props.open) {
			updateFormData(customerCreateInitialData);
		}
	}, [props.open, props.customer_id]);

	const cancelButtonRef = useRef(null);

	const handleChange = e => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};
	const handleActiveCheck = e => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.checked
		});
	};
	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		setFormErrors({});

		const errors = await validateCustomerForm(formData);
		setFormErrors(errors);
		setIsSubmitted(true);

		if (Object.keys(errors).length === 0) {
			let response;

			if (props.isEdit) {
				response = await patchContent(
					process.env.REACT_APP_NEXT_PUBLIC_API_URL + "/v1/customers/" + props.customer_id,
					formData
				);
			} else {
				response = await postContent(process.env.REACT_APP_NEXT_PUBLIC_API_URL + "/v1/customers/", formData);
			}

			if (response.ok) {
				await getCustomers();
				props.onClose();
			} else {
				// Show endpoint error if exists
				const err = await response.json();

				if (err.error && err.error === "REFERRAL_CODE_NOT_FOUND") {
					setFormErrors({
						...errors,
						referral_code: "Invalid referral code"
					});
				} else {
					setErrMsg(err.error ? displayApiError(err.error) : "Unable to create customer");
				}
			}
		}

		setLoading(false);
	};

	const resetForm = () => {
		setFormErrors({});
		updateFormData(customerCreateInitialData);
		props.onClose();
	};

	return (
		<>
			<Transition.Root show={props.open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={resetForm}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-6xl md:w-full">
								<div className="bg-white px-4 pt-5 pb-4 md:p-16 md:pb-14">
									<div className="lg:flex lg:items-start">
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-lg leading-6 font-medium text-gray-900"
											>
												{props.isEdit ? "Update" : "Create"} Customer
											</Dialog.Title>
											<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
												<div className="md:grid md:grid-cols-3 md:gap-6">
													<div className="md:col-span-1">
														<p className="mt-1 text-sm text-gray-500">
															Please fill in the necessary information to{" "}
															{props.isEdit ? "update" : "create"} your Customer
														</p>
													</div>
													<div className="mt-5 md:mt-0 md:col-span-2">
														<form action="#" method="POST">
															<div className="grid grid-cols-6 gap-6">
																{props.isEdit && (
																	<div className="active col-span-6">
																		<div className="mr-4">Active</div>
																		<label className="relative inline-flex cursor-pointer items-center">
																			<input
																				value={formData.active}
																				checked={formData.active}
																				name="active"
																				id="active"
																				onChange={handleActiveCheck}
																				type="checkbox"
																				className="peer sr-only"
																			/>
																			<label
																				htmlFor="active"
																				className="hidden"
																			></label>
																			<div
																				id="active_switch"
																				className="peer h-6 w-11 rounded-full border bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"
																			></div>
																		</label>
																	</div>
																)}
																<div className="col-span-3">
																	<label
																		htmlFor="name"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Customer Name
																	</label>
																	<input
																		value={formData.name}
																		type="text"
																		name="name"
																		id="name"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.name && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.name && (
																		<span className="text-red-700 text-sm">
																			{formErrors.name}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="email"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Email
																	</label>
																	<input
																		value={formData.email}
																		type="text"
																		name="email"
																		id="email"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.email && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.email && (
																		<span className="text-red-700 text-sm">
																			{formErrors.email}
																		</span>
																	)}
																</div>
																<div className="col-span-6">
																	<label
																		htmlFor="details"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Details
																	</label>
																	<textarea
																		value={formData.details}
																		rows={3}
																		name="details"
																		id="details"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.details && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.details && (
																		<span className="text-red-700 text-sm">
																			{formErrors.details}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="iban"
																		className="block text-sm font-medium text-gray-700"
																	>
																		IBAN
																	</label>
																	<input
																		value={formData.iban}
																		type="text"
																		name="iban"
																		id="iban"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.iban && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.iban && (
																		<span className="text-red-700 text-sm">
																			{formErrors.iban}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="phone"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Phone Number
																	</label>
																	<input
																		value={formData.phone}
																		type="text"
																		name="phone"
																		id="phone"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.phone && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.phone && (
																		<span className="text-red-700 text-sm">
																			{formErrors.phone}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="address"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Address
																	</label>
																	<input
																		value={formData.address}
																		type="text"
																		name="address"
																		id="address"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.address && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.address && (
																		<span className="text-red-700 text-sm">
																			{formErrors.address}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="city"
																		className="block text-sm font-medium text-gray-700"
																	>
																		City
																	</label>
																	<input
																		value={formData.city}
																		type="text"
																		name="city"
																		id="city"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.city && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.city && (
																		<span className="text-red-700 text-sm">
																			{formErrors.city}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="zip"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Postal Code
																	</label>
																	<input
																		value={formData.zip}
																		type="text"
																		name="zip"
																		id="zip"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.zip && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.zip && (
																		<span className="text-red-700 text-sm">
																			{formErrors.zip}
																		</span>
																	)}
																</div>
																<CountySelector
																	handleChange={handleChange}
																	country={formData.country}
																/>
																<div className="col-span-3">
																	<label
																		htmlFor="contact"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Contact Name
																	</label>
																	<input
																		value={formData.contact}
																		type="text"
																		name="contact"
																		id="contact"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.contact && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.contact && (
																		<span className="text-red-700 text-sm">
																			{formErrors.contact}
																		</span>
																	)}
																</div>
																<div className="col-span-3">
																	<label
																		htmlFor="referral_code"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Referral Code
																	</label>
																	<input
																		value={formData.referral_code}
																		type="text"
																		name="referral_code"
																		id="referral_code"
																		onChange={handleChange}
																		className={`"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${isSubmitted && formErrors.referral_code && "border-red-700"}`}
																	/>
																	{isSubmitted && formErrors.referral_code && (
																		<span className="text-red-700 text-sm">
																			{formErrors.referral_code}
																		</span>
																	)}
																</div>
															</div>
														</form>
														{errMsg && <ErrorMsgComponent errMsg={errMsg} />}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={handleSubmit}
									>
										{loading && <SpinnerSmall />}
										{!loading && (props.isEdit ? "Update" : "Create")}
									</button>
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={resetForm}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
