function postContent(url, data) {
	return fetch(url, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${localStorage.getItem("token")}`,
			"Content-type": "application/json"
		}
	}).then(async response => {
		if (response.status === 401) {
			const isNewTokenGenerated = await refreshToken();

			// Rerun method if new token is generated
			return isNewTokenGenerated && postContent(url, data);
		}

		return response;
	});
}

function patchContent(url, data) {
	return fetch(url, {
		method: "PATCH",
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${localStorage.getItem("token")}`,
			"Content-type": "application/json"
		}
	}).then(async response => {
		if (response.status === 401) {
			const isNewTokenGenerated = await refreshToken();

			// Rerun method if new token is generated
			return isNewTokenGenerated && (await patchContent(url, data));
		} else {
			return response;
		}
	});
}

function getContent(url, state = null) {
	fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("token")}`,
			"Content-type": "application/json"
		}
	}).then(async response => {
		if (response.status === 401) {
			const isNewTokenGenerated = await refreshToken();

			// Rerun method if new token is generated
			return isNewTokenGenerated && getContent(url, state);
		}

		if (!response.ok) {
			state([]);
		} else {
			response = await response.json();
			state(response);
		}
	});
}

function getContentWithoutState(url) {
	return fetch(url, {
		method: "GET",
		headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
	}).then(async response => {
		if (response.status === 401) {
			const isNewTokenGenerated = await refreshToken();

			// Rerun method if new token is generated
			return isNewTokenGenerated && getContentWithoutState(url);
		}

		if (!response.ok) {
			return [];
		} else {
			return await response.json();
		}
	});
}

function deleteContent(url) {
	return fetch(url, {
		method: "DELETE",
		headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
	}).then(async response => {
		if (response.status === 401) {
			const isNewTokenGenerated = await refreshToken();

			// Rerun method if new token is generated
			return isNewTokenGenerated && deleteContent(url);
		}

		if (!response.ok) {
			return response;
		} else {
			return response;
		}
	});
}

async function getLoginToken(data) {
	await fetch(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/auth/login`, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json"
		},
		credentials: "include"
	}).then(async response => {
		if (response.ok) {
			const token = await response.json();
			if (token.user.user_level > 60) {
				localStorage.setItem("token", token.access_token);
				return token;
			}
		}

		localStorage.removeItem("token");
		return "";
	});
}

async function checkLoginToken() {
	let check = false;
	await fetch(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/session/`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("token")}`,
			"Content-type": "application/json"
		},
		credentials: "include"
	}).then(async response => {
		if (response.status === 401) {
			const isNewTokenGenerated = await refreshToken();

			// Rerun method if new token is generated
			return isNewTokenGenerated && checkLoginToken();
		}

		const response_json = await response.json();
		if (response_json.expires * 1000 > Date.now()) {
			check = true;
		}
	});
	return check;
}

async function refreshToken() {
	return new Promise((resolve) => {
		// Attempt to refresh access token
		fetch(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/auth/refresh_token`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`
			},
			credentials: "include"
		}).then(async response => {
			// Require user to relogin if refresh token no longer able to refresh access token
			if (response.status === 401) {
				localStorage.removeItem("token");

				const currentRoute = window.location.pathname;
				if (!currentRoute.includes("login")) {
					window.location.href = `/login?path=${currentRoute}`;
				}

				return await resolve(false);
			} else {
				const token = await response.json();

				await localStorage.setItem("token", token.access_token);
				return await resolve(true);
			}
		});
	});
}

export { getContent, getContentWithoutState, postContent, patchContent, deleteContent, getLoginToken, checkLoginToken };
