import React, { useEffect, useState } from "react";
import DeleteUser from "./Modals/UserList/DeleteUser";
import Spinner from "./Spinner";
import { PlusIcon } from "@heroicons/react/solid";
import { useCommonStore } from "../stores/common-store";
import { getContent } from "../helpers/FetchContent";
import { useVideoStore } from "../stores/video-store";
import CreateContentVideo from "./Modals/ContentVideoList/CreateContentVideo";
import DeleteContentVideo from "./Modals/ContentVideoList/DeleteContentVideo";
import { getTitle } from "../helpers/Common";

export default function ContentVideosListDetails() {
	const isLoading = useCommonStore(state => state.isLoading);
	const setIsLoading = useCommonStore(state => state.setIsLoading);


	const setVideos = useVideoStore(state => state.setVideos);
	const videos = useVideoStore(state => state.videos);

	useEffect(() => {
		getVideos();
	}, []);

	const getVideos = async () => {
		await getContent(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/content_videos/`,
			setVideos
		);
		setIsLoading(false);
	};

	const [selectedVideoId, setSelectedVideoId] = useState(0);
	const [selectedTitle, setSelectedTitle] = useState("");
	const [showDetails, setShowDetails] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const handleShowDelete = (videoId, title) => {
		setSelectedVideoId(videoId);
		setShowConfirmDelete(true);
		setSelectedTitle(title);
	};

	const handleShowDetails = (videoId = 0, isEdit = false) => {
		setSelectedVideoId(videoId);
		setIsEdit(isEdit);
		setShowDetails(true);
	};

	const closeDetailsDialog = () => setShowDetails(false);
	const closeDeleteDialog = () => setShowConfirmDelete(false);

	if (!videos || isLoading) {
		return <Spinner />;
	}

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			{/* Modals [start] */}
			<DeleteContentVideo
				btnText={"Delete"}
				modalTitle={"Delete Video ID " + selectedVideoId + " "}
				modalBody={"This will delete the video"}
				video_id={selectedVideoId}
				title={selectedTitle}
				open={showConfirmDelete}
				onClose={closeDeleteDialog}
			/>
			{/* Modals [end] */}
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Video</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all our Videos.</p>
				</div>
				<div className="sm:flex-auto text-right">
						<button
						type="button"
						className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-black bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
						onClick={() => {
							handleShowDetails();
						}}
					>
						Add Video
						<PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
					</button>
					<CreateContentVideo isEdit={isEdit} video_id={selectedVideoId} open={showDetails} onClose={closeDetailsDialog} />
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
										>
											English Title
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											German Title
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Url/Path
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Order
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Details</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y-2">
									{videos.map(video => (
										<tr
											key={video.id}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
												<div className="flex items-center">
													<div className="">
														<div className="font-medium">{getTitle(video.titles, 'en')}</div>
													</div>
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{getTitle(video.titles, 'de')}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{video.url}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{video.order}</div>
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
												<span className="ml-4 space-x-4">
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowDetails(video.id, true);
														}}
													>
														Details
													</button>

													<span className="" aria-hidden="true">
														|
													</span>
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowDelete(video.id, `${getTitle(video.titles, 'en')}/${getTitle(video.titles, 'de')}`);
														}}
													>
														Delete
													</button>
												</span>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
