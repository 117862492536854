import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { checkLoginToken } from "./FetchContent";

const RouteGuard = ({ component: Component, ...rest }) => {
	const history = useHistory();

	function hasJWT() {
		let flag = false;
		localStorage.getItem("token") ? (flag = true) : (flag = false);
		return flag;
	}

	useEffect(() => {
		let isMounted = true;
		checkLoginToken().then(response => {
			if (isMounted) {
				if (!response) history.push("/login");
			}
		});
		return () => {
			isMounted = false;
		};
	}, [history]);

	return <Route {...rest} render={props => (hasJWT() ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

export default RouteGuard;
