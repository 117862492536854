const generatePassword = () => {
	// Define character sets for each category
	const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	const specialChars = "!@#$%^&*()-_+=";
	const numbers = "0123456789";

	// Initialize password string
	let password = "";

	// Generate at least 1 uppercase letter, 1 special character, and 1 number
	password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
	password += specialChars[Math.floor(Math.random() * specialChars.length)];
	password += numbers[Math.floor(Math.random() * numbers.length)];

	// Generate remaining characters
	const remainingChars = "abcdefghijklmnopqrstuvwxyz";
	const allChars = uppercaseChars + remainingChars + specialChars + numbers;

	while (password.length < 32) {
		password += allChars[Math.floor(Math.random() * allChars.length)];
	}

	// Shuffle the password to ensure randomness
	password = password
		.split("")
		.sort(() => Math.random() - 0.5)
		.join("");

	return password;
};

const customerUpdateInitialData = {
	name: "loading...",
	details: "loading...",
	iban: "loading...",
	phone: "loading...",
	email: "loading...",
	address: "loading...",
	zip: "loading...",
	contact: "loading...",
	country: "loading...",
	city: "loading...",
	active: "loading...",
	referral_code: "loading..."
};

const customerCreateInitialData = {
	name: "",
	details: "",
	iban: "",
	phone: "",
	email: "",
	address: "",
	zip: "",
	contact: "",
	city: "",
	country: "Germany"
};

const userUpdateInitialData = {
	first_name: "loading...",
	last_name: "loading...",
	active: "loading...",
	level: "loading..."
};

const userCreateInitialData = {
	email: "",
	password: generatePassword(),
	first_name: "",
	last_name: "",
	level: 1
};

const videoCreateInitialData = {
	title_en: "",
	title_de: "",
	url: "",
	order: ""
};

const videoUpdateInitialData = {
	title_en: "loading...",
	title_de: "loading...",
	url: "loading...",
	order: "loading..."
};

export {
	customerUpdateInitialData,
	customerCreateInitialData,
	userUpdateInitialData,
	userCreateInitialData,
	videoCreateInitialData,
	videoUpdateInitialData
};
