import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getContent, postContent } from "../../../helpers/FetchContent";
import SpinnerSmall from "../../SpinnerSmall";
import ErrorMsgComponent from "../../../helpers/Errors";
import { useUserStore } from "../../../stores/users-store";
import { displayApiError } from "../../../helpers/DisplayAPIError";

export default function AssignUser(props) {
	const setUsers = useUserStore(state => state.setUsers);
	const filters = useUserStore(state => state.filters);
	const [errMsg, setErrMsg] = useState("");
	const [unassignedArray, setUnassignedArray] = useState([]);
	const [loading, setLoading] = useState(false);
	const [formData, updateFormData] = useState({
		customer_id: "loading...",
		user_id: props.user_id
	});
	const [assignedCustomers, setAssignedCustomers] = useState([]);

	useEffect(() => {
		if (props.open) {
			getContent(
				`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/customers/unassigned`,
				setUnassignedArray
			);
			getAssignedCustomers();
		}
	}, [props.open]);

	const cancelButtonRef = useRef(null);

	const handleChange = e => {
		updateFormData({
			...formData,
			[e.target.name]: Number(e.target.value.trim()),
			user_id: props.user_id
		});
	};

	const getUsers = async () => {
		await getContent(`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/users/?name=${filters.name}&email=${filters.email}`, setUsers);
	};

	const getAssignedCustomers = async () => {
		await getContent(
			`${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/v1/customers/connected?user_id=${props.user_id}`,
			setAssignedCustomers
		);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		setErrMsg("");

		if (formData.customer_id > 0) {
			const response = await postContent(
				process.env.REACT_APP_NEXT_PUBLIC_API_URL + "/v1/users/assign",
				formData
			);

			if (response.ok) {
				await getUsers();
				props.onClose();
			} else {
				// Show endpoint error if exists
				const err = await response.json();
				setErrMsg(err.error ? displayApiError(err.error) : "Unable to assign customer to user");
			}
		} else {
			setErrMsg("Please select a customer to assign");
		}

		setLoading(false);
	};

	return (
		<>
			
			<Transition.Root show={props.open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={props.onClose}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-6xl md:w-full">
								<div className="bg-white px-4 pt-5 pb-4 md:p-16 md:pb-14">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											Assign User
										</Dialog.Title>

										<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mb-6 w-full">
											<div className="md:grid md:grid-cols-3 md:gap-6">
												<div className="md:col-span-1">
													<p className="mt-1 text-sm text-gray-500">
														Customer(s) assigned to user
													</p>
												</div>
												<div className="mt-5 md:mt-0 md:col-span-2">
													<table className="divide-y divide-gray-300 w-full" id="assigned_customers">
														<thead className="bg-gray-50">
															<tr>
																<th
																	scope="col"
																	className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
																>
																	Name
																</th>
																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
																>
																	Email
																</th>
															</tr>
														</thead>

														<tbody className="divide-y-2">
															{assignedCustomers.length === 0 && (
																<tr key="none">
																	<td
																		className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6 text-center"
																		colSpan={2}
																	>
																		<div className="font-medium">
																			No Assigned Customer
																		</div>
																	</td>
																</tr>
															)}
															{assignedCustomers.map(customer => (
																<tr key={!customer.id ? "none" : customer.id}>
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		{customer.name}
																	</td>
																	<td className="whitespace-nowrap px-3 py-4 text-xs">
																		{customer.email}
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>
										</div>

										<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
											<div className="md:grid md:grid-cols-3 md:gap-6">
												<div className="md:col-span-1">
													<p className="mt-1 text-sm text-gray-500">
														Please assign the user to a customer
													</p>
												</div>
												<div className="mt-5 md:mt-0 md:col-span-2">
													<form action="#" method="POST">
														<div className="grid grid-cols-6 gap-6">
															<div className="col-span-6">
																<label
																	htmlFor="customer_id"
																	className="block text-sm font-medium text-gray-700"
																>
																	Unassigned Customers
																</label>
																<select
																	id="customer_id"
																	name="customer_id"
																	onChange={handleChange}
																	className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																>
																	<option key="default" value="">
																		Select Customer
																	</option>
																	{unassignedArray.map(unassigned => (
																		<option
																			key={unassigned.id}
																			value={unassigned.id}
																		>
																			{unassigned.name}
																		</option>
																	))}
																</select>
															</div>
														</div>
													</form>
													{errMsg && <ErrorMsgComponent errMsg={errMsg} />}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={handleSubmit}
									>
										{loading && <SpinnerSmall />}
										{!loading && "Assign"}
									</button>
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={props.onClose}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
