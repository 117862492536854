import React, { useEffect, useState } from "react";
import DeleteCustomer from "./Modals/CustomerList/DeleteCustomer";
import Spinner from "./Spinner";
import { CheckIcon, XIcon, PlusIcon } from "@heroicons/react/solid";
import CustomerForm from "./Modals/CustomerList/CustomerForm";
import AssignCustomer from "./Modals/CustomerList/AssignCustomer";
import ContractCustomer from "./Modals/CustomerList/ContractCustomer";
import { useCustomerStore } from "../stores/customers-store";
import { useCommonStore } from "../stores/common-store";

export default function CustomersListDetails({ data }) {
	const isLoading = useCommonStore(state => state.isLoading);
	const customers = useCustomerStore(state => state.customers);
	const setCustomers = useCustomerStore(state => state.setCustomers);
	const [selectedCustomerId, setSelectedCustomerId] = useState(0);
	const [selectedCustomerName, setSelectedCustomerName] = useState(0);
	const [showAssign, setShowAssign] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [showContracts, setShowContracts] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		setCustomers(data);
	}, [data]);

	const handleShowAssign = customerId => {
		setSelectedCustomerId(customerId);
		setShowAssign(true);
	};

	const handleShowForm = customerId => {
		setIsEdit(true);
		setSelectedCustomerId(customerId);
		setShowForm(true);
	};

	const handleShowCreateForm = () => {
		setIsEdit(false);
		setShowForm(true);
	};

	const handleShowContracts = customerId => {
		setSelectedCustomerId(customerId);
		setShowContracts(true);
	};

	const handleShowDelete = (customerId, customerName) => {
		setSelectedCustomerId(customerId);
		setSelectedCustomerName(customerName);
		setShowConfirmDelete(true);
	};

	const closeAssignDialog = () => setShowAssign(false);
	const closeFormDialog = () => setShowForm(false);
	const closeContractsDialog = () => setShowContracts(false);
	const closeDeleteDialog = () => setShowConfirmDelete(false);

	const reload = () => window.location.reload();
	if (!customers || isLoading) {
		return <Spinner />;
	}
	return (
		<div className="px-4 sm:px-6 lg:px-8">
			{/* Modals [start] */}
			<AssignCustomer customer_id={selectedCustomerId} open={showAssign} onClose={closeAssignDialog} />

			<CustomerForm customer_id={selectedCustomerId} open={showForm} onClose={closeFormDialog} isEdit={isEdit} />

			<ContractCustomer customer_id={selectedCustomerId} open={showContracts} onClose={closeContractsDialog} />

			<DeleteCustomer
				btnText={"Delete"}
				modalTitle={"Delete Customer " + selectedCustomerName}
				modalBody={"This will delete the following Customer:"}
				customer_id={selectedCustomerId}
				customer_name={selectedCustomerName}
				open={showConfirmDelete}
				onClose={closeDeleteDialog}
			/>

			{/* Modals [end] */}
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Customers</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all our Customers.</p>
				</div>
				<div className="sm:flex-auto text-right">
					<button
						type="button"
						className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-black bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
						onClick={handleShowCreateForm}
					>
						Create Customer
						<PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
					</button>
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Contact
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Active
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Referred By
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
										>
											Referral Code
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-center text-xs font-semibold text-gray-900"
										>
											<span className="sr-only">Details</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y-2">
									{customers.map(customer => (
										<tr
											className={
												!customer.active ? "text-red-800 border-red-300 bg-red-50" : ""
											}
											key={customer.id}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
												<div className="flex items-center">
													<div className="">
														<div className="font-medium">{customer.name}</div>
													</div>
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{customer.contact}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">
													{customer.active === true && (
														<CheckIcon className="active-icon" style={{ height: 25 }} />
													)}
													{customer.active === false && (
														<XIcon className="inactive-icon" style={{ height: 25 }} />
													)}
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{customer.referred_by_name}</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-xs">
												<div className="">{customer.code}</div>
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
												<span className="ml-4 space-x-4">
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowAssign(customer.id);
														}}
													>
														Assign Customer
													</button>
													<span className="" aria-hidden="true">
														|
													</span>
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowContracts(customer.id);
														}}
													>
														Contracts
													</button>
													<span className="" aria-hidden="true">
														|
													</span>
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowForm(customer.id);
														}}
													>
														Details
													</button>
													<span className="" aria-hidden="true">
														|
													</span>
													<button
														type="button"
														className="bg-transparent rounded-md font-medium hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
														onClick={() => {
															handleShowDelete(customer.id, customer.name);
														}}
													>
														Delete
													</button>
												</span>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
